.react-resizable-handle-w {
  left: -3px;
}

.react-resizable-handle-e {
  right: -3px;
  margin-right: 5px;
}

.react-resizable-handle {
  margin-top: 0;
  cursor: ew-resize;
  transform: none;
  position: absolute;
  width: 2px;
  height: calc(100% - 34px);
  /* height: 100%; */
  box-sizing: border-box;
  background-image: none;
  background: #999;
  top: 34px;
  padding: 0;
}